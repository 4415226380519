import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Tableau de bord",
      icon: "home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path: "/admin-dashboard",
    },
    {
      headTitle1: "Commandes",
      headTitle2: "Gestion des commandes",
    },
    {
      title: "Gestion des commandes",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Listes des commandes",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "order/list-orders",
        },


      

        {
          title: "Transport",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "order/list-transport-orders",
        },
        {
          title: "Location",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "order/list-rent-orders",
        },
        {
          title: "Carburant",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "order/list-fuel-orders",
        },
        {
          title: "Assurance",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "order/list-insurance-orders",
        },

        {
          title: "Assigner les commandes",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "order/list-assignements",
        },
      ],
    },
   

    {
      headTitle1: "Transporteurs",
      headTitle2: "Gestion des recruteurs",
    },

    {
      title: "Gestion des transporteurs",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des transporteurs",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "transporter/list-transporters",
        },


      ],
    },

    {
      headTitle1: "Suivi des livraisons",
      headTitle2: "Gestion des livraisons",
    },
    {
      title: "Livraison",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Calendrier",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "calendar",
        },


      ],
    },
    {
      headTitle1: "Blog",
      headTitle2: "Gestion des articles de blog",
    },
    {
      title: "Gestion des articles",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Créer un article",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/article/create-article",
        },
        {
          title: "Liste des articles",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/article/list-articles",
        },


      ],
    },
    {
      title: "Gestion des catégories ",
      icon: "task",
      type: "sub",
      active: false,
      children: [

        {
          title: "Liste des catégories",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/category/list-categories",
        },


      ],
    },
    {
      title: "Gestion des tags",
      icon: "form",
      type: "sub",
      active: false,
      children: [

        {
          title: "Liste des tags",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/tag/list-tags",
        },


      ],
    },
    {
      title: "Gestion des status",
      icon: "form",
      type: "sub",
      active: false,
      children: [

        {
          title: "Liste des status",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/status/list-status",
        },


      ],
    },
    {
      headTitle1: "Paramètres",
      headTitle2: "Gestion des paramètres",
    },
    {
      title: "Ajouter un  véhicule",
      icon: "stroke-icon",
      type: "link",
      active: false,
      path: "/rent/vehicule/create-vehicule",
  },
  {
    title: "Liste des véhicules",
    icon: "stroke-icon",
    type: "link",
    active: false,
    path: "/rent/vehicule/list-vehicules",
},
    {
      title: "Ajouter un  tarification",
      icon: "stroke-icon",
      type: "link",
      active: false,
      path: "/rent/tarification/create-tarification",
  },
  {
    title: "Liste des tarifications",
    icon: "stroke-icon",
    type: "link",
    active: false,
    path: "/rent/tarification/list-tarifications",
},
    {
      title: "Types de compte",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-accounts", title: "Liste des types de compte", type: "link" }
      ],
    },
    {
      title: "Types de marchandise",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-merchandises", title: "Liste des types de marchandise", type: "link" }
      ],
    },    
    {
      title: "Types d'engins",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-engins", title: "Liste des engins", type: "link" }
      ],
    },
    
    {
      title: "Pays",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-countries", title: "Liste des pays", type: "link" }
      ],
    },
    {
      title: "Administrateurs",
      type: "sub",
      icon: "user",
      badgeType: "light-primary",
      active: false,
      children: [

        // { path: "/parameter/admin", title: "Ajouter un administrateur", type: "link" },
        { path: "/parameter/list-admins", title: "Liste des administrateurs", type: "link" },


      ],
    },
    {
      title: "Rôles",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-roles", title: "Liste des rôles", type: "link" }
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
